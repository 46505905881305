import React, { createContext, useContext, useState, ReactNode } from 'react';
import { themes } from '~/themes/themes';

type ThemeName = keyof typeof themes;

interface AppContextType {
  isUserMobile?: boolean;
  currentTheme?: ThemeName;
  theme: typeof themes[ThemeName];
  openCreditModal: boolean;
  setTheme: (themeName: ThemeName) => void;
  setMobile: (isMobile: boolean) => void;
  setOpenCreditModal: (isOpen: boolean) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }
  return context;
};

export default function AppContextProvider({
  children,
  isUserMobile,
  initialTheme = 'light',
}: {
  children: ReactNode;
  isUserMobile?: boolean;
  initialTheme?: ThemeName;
}) {
  const [currentTheme, setCurrentTheme] = useState<ThemeName>(initialTheme);
  const [openCreditModal, setOpenCreditModal] = useState(false);
  const [userIsMobile, setUserMobile] = useState(false);

  const changeTheme = (themeName: ThemeName) => {
    setCurrentTheme(themeName);
  };
  const makeMobile = (isMobile: boolean) => {
    setUserMobile(isMobile);
  };

  const toggleCreditModal = (isOpen: boolean) => {
    setOpenCreditModal(isOpen);
  };

  return (
    <AppContext.Provider
      value={{
        isUserMobile: userIsMobile,
        currentTheme,
        theme: themes[currentTheme],
        openCreditModal,
        setTheme: changeTheme,
        setMobile: makeMobile,
        setOpenCreditModal: toggleCreditModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
